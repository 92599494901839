console.log('Vite ⚡️ Rails')

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

// jquery
// import "../javascripts/jquery"

// Bootstrap
import "bootstrap"

// import "../javascripts/plugins.min"
// import "../javascripts/custom.min"


(function() {
  document.querySelectorAll('[data-background]').forEach(function (background, index) {

    if (background.getAttribute('data-gradient') == 1) {
      background.append('<div class="overlay-background overlay-gradient"></div>')
      background.querySelector('.overlay-background').style.opacity = background.getAttribute('data-overlay')
    } else if (background.getAttribute('data-overlay') > 0) {
      // background.append('<div class="overlay-background"></div>')
      // background.querySelector('.overlay-background').style.opacity = background.getAttribute('data-overlay')
      let div = document.createElement('div')
      div.classList.add("overlay-background")
      div.style.opacity = background.getAttribute('data-overlay')
      background.append(div)
    }

    background.style.backgroundImage = `url(${background.getAttribute('data-background')})`
  })
})()